import { Routes, Route } from 'react-router-dom'
import React from 'react';
import Home from './pages/Home'
import Contacts from './pages/Contacts'
import Case from './pages/Case'
import Header from './components/Header'
import Footer from './components/Footer'
import {ModalProvider} from "./context";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc8hPolAAAAACBzi0LxFubdAOQOpcm5sP7Z4TJy">
        <ModalProvider>
            <div className="wrapper">
                <Header/>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/cases/:caseId" element={<Case />} />
                </Routes>
                <Footer/>
            </div>
        </ModalProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
