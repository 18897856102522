import { useState, useEffect, useRef } from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useModal, useProfile} from "../context";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Slider from "react-slick"
import CustomScroll from 'react-custom-scroll';
import Info from "../components/modals/Info";

let sl_set = {
    dots: true,
    arrows: false,
    speed: 600,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    waitForAnimate: true,
    pauseOnHover: false,
    fade: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};

let sl_set_media = {
    dots: true,
    arrows: false,
    speed: 600,
    infinite: true,
    waitForAnimate: true,
    autoplay: true,
    autoplaySpeed: 4500,
    centerMode: true,
    centerPadding: 0,
    pauseOnHover: false,
    variableWidth: true,
    focusOnSelect: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                variableWidth: false,
            }
        }
    ]
};

function Case() {
    const [loading, setLoading] = useState(false);
    const { caseId } = useParams();

    return (
        <>
            {caseId == 'svoe' && (
                <div className="page page--case page--case-svoe">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/svoe/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/svoe/logo.svg").default}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    ФЕРМЕРСКИЙ <br/>
                                    ФЕСТИВАЛЬ «СВОЁ»
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--1">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        5
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        городов
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    Пермь, Тюмень, Кемерово, <br/>
                                                    Ижевск, Улан-Удэ
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        360 000
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        гостей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    принял наш <br/>
                                                    фестиваль
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        200&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        раз
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    СВОЁ упомянули <br/>
                                                    в региональных СМИ
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--left desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/svoe/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/svoe/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/svoe/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/svoe/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/svoe/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="sq__box sq__box--left desk-only">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/svoe/logo-ct.jpg")}/>
                            </div>
                            <div className="about__descr">
                                масштабно поддержали сельское хозяйство: <br/>
                                под ключ организовали серию городских фермерских фестивалей “СВОЁ”
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="sq__box sq__box--right">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/svoe/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/svoe/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/svoe/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--1">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        80
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        тонн
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    фермерских продуктов продали <br/>
                                                    за время работы фестиваля
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        250
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        музыкальных
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    выступлений увидели <br/>
                                                    гости праздника
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        540
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        фермерcких
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    хозяйств рассказали <br/>
                                                    о своих продуктах
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--4">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        3
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        кита
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    поддержки властей: Администрация города, <br/>
                                                    Министерство сельского хозяйства, Губернатор области
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/svoe/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/svoe/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/svoe/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/svoe/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/svoe/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'weber' && (
                <div className="page page--case page--case-weber">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/weber/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo">
                            <img src={require("../assets/img/cases/weber/logo.svg").default}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/weber/logo-2.svg").default}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    ОРГАНИЗАЦИЯ <br/>
                                    СЕТА ГРИЛЬ ФЕСТОВ <br/>
                                    WEBER
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--1">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        35
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        гриль <br/>
                                                        корнеров
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    разного формата было <br/>
                                                    застроено в Московских ТЦ <br/>
                                                    и дилерских салонах
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        1
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        гриль <br/>
                                                        фест
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    был интегрирован в большую <br/>
                                                    игру на Розе Хутор
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        15 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        пользователей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    перешли на сайт с QR кодов, <br/>
                                                    размещенных на POSM
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--left desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--2">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/weber/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/weber/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/weber/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/weber/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/weber/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="sq__box sq__box--left desk-only">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/weber/logo-2.svg").default}/>
                            </div>
                            <div className="about__descr">
                                Поучаствовали в Роза Фест <br/>
                                и рассказали о грилях в Москве: <br/>
                                готовили и дегустировали <br/>
                                лучшие блюда вместе с бренд <br/>
                                шефом Академии гриля Weber
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="sq__box sq__box--right desk-only">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/weber/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/weber/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/weber/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--1">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        10 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        уникальных
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    участников зарегистрировались <br/>
                                                    в базе данных и получили призы
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        40 000
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        блюд
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    с различной рецептурой <br/>
                                                    попробовали гости
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        50%
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        увеличения
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    объёма продаж мы <br/>
                                                    зафиксировали в период <br/>
                                                    проведения фестов
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--3">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/weber/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/weber/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/weber/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/weber/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/weber/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'gorilla-2' && (
                <div className="page page--case page--case-gorilla">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/gorilla/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo">
                            <img src={require("../assets/img/cases/gorilla/logo.png")}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/gorilla/logo-2.png")}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    ИНТЕГРАЦИЯ GORILLA <br/>
                                    UFC / OFC / Fightnigts / <br/>
                                    Чемпионат России <br/>
                                    по баскетболу / <br/>
                                    Eagle fightnights
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        50&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        интеграций
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    в самые крутые проекты <br/>
                                                    в OFC / UFC / Fighting's
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--2">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        #1
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        ринг-аннонсер <br/>
                                                        и ведущий
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    Gorilla Energy со своей <br/>
                                                    командой и Топ-менеджмент <br/>
                                                    компании лично
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        12
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        каналов
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    транслировали событие онлайн
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--4">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="sq__box sq__box--left desk-only">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/gorilla/logo-2.png")}/>
                            </div>
                            <div className="about__descr">
                                Интегрировали мощь Gorilla <br/>
                                Energy в знаковые события, <br/>
                                чтобы зарядить гостей <br/>
                                семплами и интерактивами
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="sq__box sq__box--right desk-only">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--1">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        50 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        сэмплов
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    раздали промоутеры <br/>
                                                    бренда
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--2">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        1000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        изданий
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    упомянули <br/>
                                                    событие
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--5">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'gorilla' && (
                <div className="page page--case page--case-gorilla page--case-gorilla--2">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/gorilla-2/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo">
                            <img src={require("../assets/img/cases/gorilla/logo.png")}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/gorilla/logo-2.png")}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    Интеграция Gorilla <br/>
                                    energy в мероприятия
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--1">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        50&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        интеграций
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    в самые крутые проекты <br className="desk-only"/>
                                                    в OFC / UFC / Fighting's
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        12 000
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        зрителей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    отрывались на фесте Маятник <br className="desk-only"/>
                                                    Фуко при поддержке Gorilla Energy
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        10 000
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        человек
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    болели за спортсменов <br className="desk-only"/>
                                                    ВТБ Кубка Кремля по теннису <br className="desk-only"/>
                                                    вместе с Gorilla Energy
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--4">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/gorilla/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="sq__box sq__box--left desk-only">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/gorilla/logo-2.png")}/>
                            </div>
                            <div className="about__descr">
                                Интегрировали мощь Gorilla <br/>
                                Energy в знаковые события, <br/>
                                чтобы зарядить гостей <br/>
                                семплами и интерактивами
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="sq__box sq__box--right desk-only">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--1">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        50 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        сэмплов
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    раздали промоутеры <br/>
                                                    бренда
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--6">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title text-nowrap">
                                                        150 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep mb-only"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        человек
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    посетили бренд корнеры во время мероприятий
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--5">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/gorilla/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'gorilla-habib' && (
                <div className="page page--case page--case-gorilla-habib">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/gorilla-habib/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo">
                            <img src={require("../assets/img/cases/gorilla/logo.png")}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/gorilla/logo-2.png")}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    КОНФЕРЕНЦИЯ <br/>
                                    С ХАБИБОМ ДЛЯ <br/>
                                    GORILLA ENERGY
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--1">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        #1
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        амбассадор
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    Gorilla Energy со своей <br/>
                                                    командой и Топ-менеджмент <br/>
                                                    компании лично
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--2">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        300&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        представителей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    СМИ присутствовали <br/>
                                                    на презентации
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="sq__box sq__box--left">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/gorilla/logo-2.png")}/>
                            </div>
                            <div className="about__descr">
                                Организовали подписание <br/>
                                первого в&nbsp;истории 5-летнего <br/>
                                рекламного контракта <br/>
                                на $ 7 млрд
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="sq__box sq__box--right">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla-habib/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla-habib/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla-habib/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        150&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        представителей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    СМИ могли задать <br/>
                                                    вопрос Хабибу
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        7
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        сторон
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    транслировали событие <br/>
                                                    онлайн
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--7">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/gorilla-habib/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'fitroo' && (
                <div className="page page--case page--case-fitroo">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/fitroo/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo">
                            <img src={require("../assets/img/cases/fitroo/logo.png")}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/fitroo/logo-2.png")}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    ПРЕСС КОНФЕРЕНЦИЯ <br/>
                                    ХАБИБ И FITROO
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        2
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        стороны
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    презентовали новые <br/>
                                                    протеиновые батончики: Хабиб <br/>
                                                    и его команда и Топ-менеджеров
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--8">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/fitroo/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/fitroo/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/fitroo/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/fitroo/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/fitroo/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="sq__box sq__box--left">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/fitroo/logo-2.png")}/>
                            </div>
                            <div className="about__descr">
                                Рассказали всему миру о новой <br/>
                                линейке продукции бренда <br/>
                                Fitroo by Khabib
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="sq__box sq__box--right">
                                <div className="sq__it sq__it--1"/>
                                <div className="sq__it sq__it--2"/>
                                <div className="sq__it sq__it--3"/>
                                <div className="sq__it sq__it--4"/>
                                <div className="sq__it sq__it--5"/>
                                <div className="sq__it sq__it--6"/>
                                <div className="sq__it sq__it--7"/>
                                <div className="sq__it sq__it--8"/>
                            </div>
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/fitroo/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/fitroo/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/fitroo/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        300&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        представителей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    СМИ присутствовали <br/>
                                                    на презентации
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        2 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        участников
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    были приглашены на пресс-<br className="desk-only"/>
                                                    конференцию
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--5">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header mb-wrap d-flex align-items-center justify-content-end">
                                                    <div className="data__slide-title">
                                                        7
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        каналов
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    транслировали событие онлайн
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--9">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/fitroo/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/fitroo/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/fitroo/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/fitroo/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/fitroo/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'agrocode' && (
                <div className="page page--case page--case-agrocode">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/agrocode/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo desk-only">
                            <img src={require("../assets/img/cases/agrocode/logo.svg").default}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/agrocode/logo-2.svg").default}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    ON LINE + OFFLINE <br/>
                                    КОНФЕРЕНЦИЯ <br/>
                                    "АГРОКОД"
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        100&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        спикеров
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr">
                                                    спикеры со всех <br/>
                                                    регионов РФ
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1 500&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        гостей мероприятия
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/agrocode/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/agrocode/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/agrocode/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/agrocode/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/agrocode/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/agrocode/logo-2.svg").default}/>
                            </div>
                            <div className="about__descr">
                                Организовали конференцию <br/>
                                Агрокод 2022 и вручили <br/>
                                одноименную премию онлайн <br/>
                                и оффлайн
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/agrocode/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/agrocode/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/agrocode/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        25
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        наград
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    вручены лидерами <br/>
                                                    агротехнологичных проектов
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        300&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        участников
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    со всей России подключились <br/>
                                                    в Teams онлайн
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--5">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/agrocode/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/agrocode/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/agrocode/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/agrocode/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/agrocode/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'gorilla-3' && (
                <div className="page page--case page--case-gorilla-3">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/gorilla-3/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo desk-only">
                            <img src={require("../assets/img/cases/gorilla-3/logo.png")}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/gorilla-3/logo-2.png")}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    иНТЕГРАЦИЯ В VK FEST <br className="desk-only"/>
                                    2023 C&nbsp;GORILLA ENERGY
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        5
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        активностей <br/>
                                                        на стенде
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        15 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        посетителей стенда
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        50 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        банок <br/>
                                                        Gorilla
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    роздано участникам <br/>
                                                    фестиваля
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/gorilla-3/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/gorilla-3/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/gorilla-3/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/gorilla-3/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/gorilla-3/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/gorilla-3/logo-2.png")}/>
                            </div>
                            <div className="about__descr">
                                Застроили креативный стенд <br/>
                                Gorilla на VK FEST 2023 и навели <br/>
                                шуму классными активностями
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla-3/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla-3/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/gorilla-3/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        3
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        города
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    Москва, Санкт-Петербург, Сочи
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1 500&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        подключений
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    к онлайн трансляции
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--5">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/gorilla-3/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/gorilla-3/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/gorilla-3/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/gorilla-3/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/gorilla-3/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'yarmarka' && (
                <div className="page page--case page--case-yarmarka">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/yarmarka/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo desk-only">
                            <img src={require("../assets/img/cases/yarmarka/logo.svg").default}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/yarmarka/logo-2.svg").default}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    ПЕРВАЯ ВСЕРОЙСИЙСКАЯ <br/>
                                    ЯРМАРКА ОРГАНИЧЕСКОЙ <br/>
                                    ПРОДУКЦИИ
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        45 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        посетителей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    приняли участие <br/>
                                                    в активностях Ярмарки
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        60&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        фермерских <br/>
                                                        хозяйств
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    представили свою <br/>
                                                    продукцию
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        15+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        тонн
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    органической продукции <br/>
                                                    продано за время работы
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/yarmarka/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/yarmarka/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/yarmarka/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/yarmarka/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/yarmarka/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/yarmarka/logo-2.svg").default}/>
                            </div>
                            <div className="about__descr">
                                Собрали участников и провели первую ярмарку органической продукции для Россельхозбанка
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/yarmarka/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/yarmarka/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/yarmarka/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        50
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        лекций
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    было прочитано в рамках <br/>
                                                    обучающей программы
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--5">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/yarmarka/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/yarmarka/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/yarmarka/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/yarmarka/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/yarmarka/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'pridex' && (
                <div className="page page--case page--case-pridex">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/pridex/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo desk-only">
                            <img src={require("../assets/img/cases/pridex/logo.svg").default}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/pridex/logo-2.svg").default}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    PRIDEX 15 ЛЕТ: ВЫСТАВКА <br className="desk-only"/>
                                    И&nbsp;КОНФЕРЕнЦИЯ ДЛЯ <br className="desk-only"/>
                                    ПАРТНЕРОВ БИЗНЕСА
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        100&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        стендов
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    застроено в рамках <br/>
                                                    выставки
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        100&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        спикеров
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    выступили в рамках <br/>
                                                    деловой программы
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        3 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        человек
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    приняли участие <br/>
                                                    в мероприятии
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/pridex/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/pridex/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/pridex/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/pridex/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/pridex/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/pridex/logo-2.svg").default}/>
                            </div>
                            <div className="about__descr">
                                Организовали выставку для партнеров PRIDEX и&nbsp;провели конференцию в&nbsp;честь 15-летия
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/pridex/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/pridex/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/pridex/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        100&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        граффити
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    оставлено гостями <br/>
                                                    с пожеланиями для PRIDEX
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        юбилейный <br/>
                                                        торт
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    был разрезан на вечеринке
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--5">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/pridex/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/pridex/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/pridex/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/pridex/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/pridex/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'samokat' && (
                <div className="page page--case page--case-samokat">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/samokat/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/samokat/logo.svg").default}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    ОРГАНИЗОВАЛИ <br className="desk-only"/>
                                    ИНТЕГРАЦИЮ В&nbsp;ЗАБЕГ.РФ <br className="desk-only"/>
                                    В&nbsp;13&nbsp;ГОРОДАХ <br className="desk-only"/>
                                    ОДНОВРЕМЕННО
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        10 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        посетителей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    сфотографировалось <br/>
                                                    в креативной фотозоне
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        5 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        сэмплов
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    выдано через приложение <br/>
                                                    по специально разработанной механике
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/samokat/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/samokat/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/samokat/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/samokat/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/samokat/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/samokat/logo.svg").default}/>
                            </div>
                            <div className="about__descr">
                                Представили Самокат в&nbsp;серии <br className="desk-only"/>
                                мероприятий <a href="https://xn--80acghh.xn--p1ai/" target="_blank">забег.рф</a> <br className="desk-only"/>
                                в&nbsp;13&nbsp;городах одновременно
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/samokat/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/samokat/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/samokat/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        кодов на скидку
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    реализовано во время и после <br/>
                                                    мероприятия
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        3 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        новых пользователей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    скачали приложение Самокат
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--5">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/samokat/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/samokat/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/samokat/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/samokat/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/samokat/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'kaspersky' && (
                <div className="page page--case page--case-kaspersky">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/kaspersky/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo desk-only">
                            <img src={require("../assets/img/cases/kaspersky/logo.png")}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/kaspersky/logo-2.png")}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    НОВОГОДНИЙ КОРНЕР <br className="desk-only"/>
                                    ДЛЯ KASPERSKY
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        ребрендинг
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    материалов <br/>
                                                    2023 года
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--2">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        70
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        SKU
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    выложено на полках <br/>
                                                    эффективно и с любовью
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/kaspersky/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/kaspersky/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/kaspersky/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/kaspersky/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/kaspersky/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/kaspersky/logo-2.png")}/>
                            </div>
                            <div className="about__descr">
                                Продвинули собственный мерч <br className="desk-only"/>
                                Kaspersky в&nbsp;ярком новогоднем <br className="desk-only"/>
                                корнере в&nbsp;офисе компании
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/kaspersky/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/kaspersky/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/kaspersky/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        3 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        оффлайн продаж
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        2
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        коллекции
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    (2022 и 2023 года) <br/>
                                                    распродано полностью
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__bottom">
                        <div className="container d-flex justify-content-end">
                            <Link to="/cases/flagman" className="next__btn d-flex align-items-center justify-content-center">
                                <div className="next__btn-ct">
                                    <div className="next__btn-title">
                                        смотреть <br/>
                                        следующий <br/>
                                        кейс
                                    </div>
                                    <div className="next__btn-arrow">
                                        <svg width="73" height="24" viewBox="0 0 73 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5607 10.9393C64.1464 11.5251 64.1464 12.4749 63.5607 13.0607L54.0147 22.6066C53.4289 23.1924 52.4792 23.1924 51.8934 22.6066C51.3076 22.0208 51.3076 21.0711 51.8934 20.4853L60.3787 12L51.8934 3.51472C51.3076 2.92893 51.3076 1.97919 51.8934 1.3934C52.4792 0.807611 53.4289 0.807611 54.0147 1.3934L63.5607 10.9393ZM10.5 10.5L62.5 10.5V13.5L10.5 13.5V10.5Z" fill="#FFA56E"/>
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'flagman' && (
                <div className="page page--case page--case-flagman">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/flagman/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/flagman/logo.png")}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    КАПСУЛА ВРЕМЕНИ <br className="desk-only"/>
                                    ДЛЯ ФСК Регион
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1 КАПСУЛА
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        времени
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1 БУДУЩЕЕ
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        для жителей Владивостока
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--light">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        ПРЕДСЕДАТЕЛЬ
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        Правительства
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    Приморского края <br/>
                                                    и Глава города
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--11">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/flagman/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/flagman/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/flagman/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/flagman/g-1-img-4.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/flagman/logo.png")}/>
                            </div>
                            <div className="about__descr">
                                Заложили капсулу времени  <br className="desk-only"/>
                                в&nbsp;фундамент будущего вместе <br className="desk-only"/>
                                с&nbsp;ФСК Регион
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/flagman/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/flagman/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/flagman/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        200&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        гостей
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        100&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        упоминаний в СМИ
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/flagman/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/flagman/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/flagman/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/flagman/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/flagman/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__bottom">
                        <div className="container d-flex justify-content-end">
                            <Link to="/cases/en,m" className="next__btn d-flex align-items-center justify-content-center">
                                <div className="next__btn-ct">
                                    <div className="next__btn-title">
                                        смотреть <br/>
                                        следующий <br/>
                                        кейс
                                    </div>
                                    <div className="next__btn-arrow">
                                        <svg width="73" height="24" viewBox="0 0 73 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5607 10.9393C64.1464 11.5251 64.1464 12.4749 63.5607 13.0607L54.0147 22.6066C53.4289 23.1924 52.4792 23.1924 51.8934 22.6066C51.3076 22.0208 51.3076 21.0711 51.8934 20.4853L60.3787 12L51.8934 3.51472C51.3076 2.92893 51.3076 1.97919 51.8934 1.3934C52.4792 0.807611 53.4289 0.807611 54.0147 1.3934L63.5607 10.9393ZM10.5 10.5L62.5 10.5V13.5L10.5 13.5V10.5Z" fill="#FFA56E"/>
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'en' && (
                <div className="page page--case page--case-en">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/en/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo desk-only">
                            <img src={require("../assets/img/cases/en/logo.svg").default}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/en/logo-2.svg").default}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    ДЕНЬ ЭНЕРГЕТИКА <br className="desk-only"/>
                                    ДЛЯ ЭН+
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        260&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        гостей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    зажигали <br/>
                                                    вместе с нами
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--2">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        7+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        часов
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    бесконечной энергии произведено <br/>
                                                    сотрудниками компании
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/en/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/en/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/en/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/en/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/en/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/en/logo-3.svg").default}/>
                            </div>
                            <div className="about__descr">
                                Зажгли в&nbsp;День Энергетика вместе <br className="desk-only"/>
                                с&nbsp;компанией&nbsp;ЭН+
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/en/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/en/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/en/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        30&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        лучших
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    сотрудников года получили <br/>
                                                    премии и подарки
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        700&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        фотографий
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    создал <br/>
                                                    нейрохудожник
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/en/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/en/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/en/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/en/g-2-img-4.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__bottom">
                        <div className="container d-flex justify-content-end">
                            <Link to="/cases/hmao-ygra" className="next__btn d-flex align-items-center justify-content-center">
                                <div className="next__btn-ct">
                                    <div className="next__btn-title">
                                        смотреть <br/>
                                        следующий <br/>
                                        кейс
                                    </div>
                                    <div className="next__btn-arrow">
                                        <svg width="73" height="24" viewBox="0 0 73 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5607 10.9393C64.1464 11.5251 64.1464 12.4749 63.5607 13.0607L54.0147 22.6066C53.4289 23.1924 52.4792 23.1924 51.8934 22.6066C51.3076 22.0208 51.3076 21.0711 51.8934 20.4853L60.3787 12L51.8934 3.51472C51.3076 2.92893 51.3076 1.97919 51.8934 1.3934C52.4792 0.807611 53.4289 0.807611 54.0147 1.3934L63.5607 10.9393ZM10.5 10.5L62.5 10.5V13.5L10.5 13.5V10.5Z" fill="#FFA56E"/>
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'hmao-ygra' && (
                <div className="page page--case page--case-hmao-ygra">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/hmao-ygra/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo desk-only">
                            <img src={require("../assets/img/cases/hmao-ygra/logo.png")}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/hmao-ygra/logo.png")}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    СТЕНД ХМАО-ЮГРА <br className="desk-only"/>
                                    НА&nbsp;ВЫСТАВКЕ «РОССИЯ»
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        стенд региона
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    который представил свои <br/>
                                                    важные достижения
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--2">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        30
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        дней
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    беспрерывного монтажа
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        6
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        месяцев
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    поддержки и обеспечения, <br/>
                                                    в том числе контентного
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/hmao-ygra/logo.png")}/>
                            </div>
                            <div className="about__descr">
                                Представили достижения <br className="desk-only"/>
                                ХАНТЫ-МАНСИЙСКОГО <br className="desk-only"/>
                                АВТОНОМНОГО ОКРУГА&nbsp;&mdash; ЮГРЫ <br className="desk-only"/>
                                на&nbsp;выставке &laquo;Россия&raquo;
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/hmao-ygra/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/hmao-ygra/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/hmao-ygra/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1 000 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        гостей
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    на стенде <br/>
                                                    в дни открытия
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        320&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        упоминания
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    в региональных <br/>
                                                    и федеральных СМИ
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        AR
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        мамонт
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    с голосом Степана Старчикова, <br/>
                                                    приглашающий посетить регион
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/hmao-ygra/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__bottom">
                        <div className="container d-flex justify-content-end">
                            <Link to="/cases/svoe-fest" className="next__btn d-flex align-items-center justify-content-center">
                                <div className="next__btn-ct">
                                    <div className="next__btn-title">
                                        смотреть <br/>
                                        следующий <br/>
                                        кейс
                                    </div>
                                    <div className="next__btn-arrow">
                                        <svg width="73" height="24" viewBox="0 0 73 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5607 10.9393C64.1464 11.5251 64.1464 12.4749 63.5607 13.0607L54.0147 22.6066C53.4289 23.1924 52.4792 23.1924 51.8934 22.6066C51.3076 22.0208 51.3076 21.0711 51.8934 20.4853L60.3787 12L51.8934 3.51472C51.3076 2.92893 51.3076 1.97919 51.8934 1.3934C52.4792 0.807611 53.4289 0.807611 54.0147 1.3934L63.5607 10.9393ZM10.5 10.5L62.5 10.5V13.5L10.5 13.5V10.5Z" fill="#FFA56E"/>
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </section>
                </div>
            )}

            {caseId == 'svoe-fest' && (
                <div className="page page--case page--case-svoe-fest">
                    <section className="sc__case-promo" style={{backgroundImage: 'url(' + require("../assets/img/cases/svoe-fest/promo-bg.jpg") + ')'}}>
                        <div className="sq__box sq__box--left">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="case-promo__logo desk-only">
                            <img src={require("../assets/img/cases/svoe-fest/logo.png")}/>
                        </div>
                        <div className="case-logo">
                            <img src={require("../assets/img/cases/svoe-fest/logo-2.svg").default}/>
                        </div>
                    </section>
                    <section className="sc__facts">
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title">
                                    РОЖДЕСТВЕНСКИЙ <br className="desk-only"/>
                                    ФЕСТИВАЛЬ «СВОЕ» <br className="desk-only"/>
                                    ДЛЯ РОССЕЛЬХОЗБАНКА
                                </div>
                            </div>
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1 ШОУ
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        Ильи Авербуха
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    «Корова на льду» <br/>
                                                    в Лужниках
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--2">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        ярмарка
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    фермерской продукции <br/>
                                                    параллельно шоу
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        67 000&nbsp;+
                                                    </div>
                                                    <div className="data__slide-sep"/>
                                                    <div className="data__slide-subtitle mb-small">
                                                        посетителей фестиваля
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--1">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/svoe-fest/g-1-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/svoe-fest/g-1-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/svoe-fest/g-1-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/svoe-fest/g-1-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/svoe-fest/g-1-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-about">
                        <div className="container">
                            <div className="about__logo">
                                <img src={require("../assets/img/cases/svoe-fest/logo-2.svg").default}/>
                            </div>
                            <div className="about__descr">
                                Организовали рождественский <br className="desk-only"/>
                                фестиваль &laquo;СВОЁ&raquo; от&nbsp;Россельхозбанка <br className="desk-only"/>
                                в&nbsp;новогоднем шоу Ильи Авербуха
                            </div>
                        </div>
                    </section>
                    <section className="sc__case-media">
                        <div className="container">
                            <div className="media__sl-box">
                                <div className="media__sl">
                                    <Slider {...sl_set_media}>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/svoe-fest/m-1.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/svoe-fest/m-2.jpg")}/>
                                            </div>
                                        </div>
                                        <div className="media__slide">
                                            <div className="media__slide-img">
                                                <img src={require("../assets/img/cases/svoe-fest/m-3.jpg")}/>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__facts-center">
                        <div className="container">
                            <div className="data__sl-box">
                                <div className="data__sl">
                                    <Slider {...sl_set}>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        15
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        фермерских хозяйств
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        1,5+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        тонны
                                                    </div>
                                                </div>
                                                <div className="data__slide-descr mb-abs">
                                                    проданной <br/>
                                                    продукции
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data__slide data__slide--3">
                                            <div className="data__slide-bl d-flex align-items-center">
                                                <div className="data__slide-header">
                                                    <div className="data__slide-title">
                                                        300&nbsp;+
                                                    </div>
                                                    <div className="data__slide-subtitle mb-small">
                                                        публикаций в СМИ
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__gallery sc__gallery--2">
                        <div className="sq__box sq__box--right">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="container">
                            <div className="gall__box gall__box--10">
                                <div className="gall__img gall__img--1">
                                    <img src={require("../assets/img/cases/svoe-fest/g-2-img-1.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--2">
                                    <img src={require("../assets/img/cases/svoe-fest/g-2-img-2.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--3">
                                    <img src={require("../assets/img/cases/svoe-fest/g-2-img-3.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--4">
                                    <img src={require("../assets/img/cases/svoe-fest/g-2-img-4.jpg")}/>
                                </div>
                                <div className="gall__img gall__img--5">
                                    <img src={require("../assets/img/cases/svoe-fest/g-2-img-5.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="sc__bottom">
                        <div className="container d-flex justify-content-end">
                            <Link to="/cases/weber" className="next__btn d-flex align-items-center justify-content-center">
                                <div className="next__btn-ct">
                                    <div className="next__btn-title">
                                        смотреть <br/>
                                        следующий <br/>
                                        кейс
                                    </div>
                                    <div className="next__btn-arrow">
                                        <svg width="73" height="24" viewBox="0 0 73 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.5607 10.9393C64.1464 11.5251 64.1464 12.4749 63.5607 13.0607L54.0147 22.6066C53.4289 23.1924 52.4792 23.1924 51.8934 22.6066C51.3076 22.0208 51.3076 21.0711 51.8934 20.4853L60.3787 12L51.8934 3.51472C51.3076 2.92893 51.3076 1.97919 51.8934 1.3934C52.4792 0.807611 53.4289 0.807611 54.0147 1.3934L63.5607 10.9393ZM10.5 10.5L62.5 10.5V13.5L10.5 13.5V10.5Z" fill="#FFA56E"/>
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </section>
                </div>
            )}
        </>
    );
}

export default Case;
