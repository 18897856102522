import {useState, useEffect, useCallback} from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal} from "../../context";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Info from "./Info";

const schema = yup.object({
    name: yup.string().required('Некорректное Имя'),
    email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
    message: yup.string().required('Обязательно к заполнению'),
}).required();

function Order(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [captcha, setCaptcha] = useState('');

    const { show, hide} = useModal();

    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        setCaptcha(token);
        }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const { register, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        if(captcha !== '' && captcha){
            setIsSubmitting(true);
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("email", data.email);
            formData.append("message", data.message);
            formData.append("rules_agreement", 1);
            formData.append("g-recaptcha-response", captcha);

            await fetch(process.env.REACT_APP_API_PROXY + 'sendFeedback', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if(response.error == 0){
                    show(<Info text={'Спасибо! Мы свяжемся с вами в ближайшее время.'}/>, "modal--form modal--info");
                }else{
                    if(response.message.email){
                        setError('email', { type: 'custom', message: response.message.email });
                    }
                }

                setIsSubmitting(false);
            });
        }
    };

    const onCaptchaChange = (value) => {
        console.log('captcha: ', value);
        setCaptcha(value);
    }

    return (
        <>
            <div className="modal__header">
                <div className="modal__title">
                    Заказать оценку <br/>
                    стоимости проектв
                </div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                <div className="form__list">
                    <div className="fake-field">
                        <input type="text"/>
                    </div>
                    <div className="row">
                        <div className="col-6 form__col">
                            <div className="modal__form-it">
                                <div className={'modal__form-field' + (errors.name ? ' has-error' : '')}>
                                    <input
                                        type="text"
                                        id="name"
                                        {...register("name")}
                                        placeholder="Ваше имя"
                                    />
                                </div>
                                {errors.name && (
                                    <p className="error__message">
                                        {errors.name?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-6 form__col">
                            <div className="modal__form-it">
                                <div className="modal__form-field">
                                    <div className={'modal__form-field' + (errors.email ? ' has-error' : '')}>
                                        <input
                                            type="text"
                                            id="email"

                                            autoFocus={true}
                                            {...register("email")}
                                            placeholder="Ваш E-mail"
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 form__col">
                            <div className="modal__form-it">
                                <div className="modal__form-field">
                                    <div className={'modal__form-field' + (errors.email ? ' has-error' : '')}>
                                        <textarea
                                            id="mess"
                                            {...register("message")}
                                            placeholder="Сообщение"
                                        />
                                    </div>
                                    {errors.message && (
                                        <p className="error__message">
                                            {errors.message?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 form__col">
                            <div className="modal__actions d-flex justify-content-center">
                                <div className={'modal__form-submit' + (isSubmitting ? ' disabled' : '')}>
                                    <button type="submit" className="btn__custom d-flex align-items-center justify-content-center">
                                        <span>Отправить сообщение</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </>
    );
}

export default Order;
