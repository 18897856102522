import { useState, useEffect, useRef } from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useModal, useProfile} from "../context";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Slider from "react-slick"
import { gsap } from 'gsap';

let myMap = null;

function Home() {
    const [loaded, setLoaded] = useState(false);
    const [routed, setRouted] = useState(false);
    let added = false;

    useEffect(() => {
        if(!added && !window.ymaps){
            const scriptTag = document.createElement('script');
            scriptTag.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=41a48866-0d14-4bfd-b4cc-f57faa2244d3';
            scriptTag.addEventListener('load', () => {
                setLoaded(true);
                initMap();
            });
            document.body.appendChild(scriptTag);
            added = true;
        }else{
            if(!added){
                initMap();
                added = true;
            }
        }

    }, []);

    useEffect(() => {
        if(!loaded) return;
    }, [loaded]);

    const initMap = () => {
        window.ymaps.ready(function (){
            let controls = ['zoomControl', 'routeButtonControl'];
            myMap = new window.ymaps.Map('map', {
                center: [55.725164, 37.64745],
                zoom: 15,
                controls: controls
            },  {
                searchControlProvider: 'yandex#search'
            });

            if( (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ) {
                myMap.behaviors.disable('multiTouch');
                myMap.behaviors.disable('drag');
            }

            myMap.behaviors.disable('scrollZoom');

            let place = new window.ymaps.Placemark([55.725164, 37.64745], {}, {});
            myMap.geoObjects.add(place);
        });

    }

    const buildRoute = () => {
        let control = myMap.controls.get('routeButtonControl');

        control.routePanel.geolocate('from');

        control.routePanel.state.set({
            type: 'auto',
            fromEnabled: false,
            toEnabled: false,
            to: [55.725164, 37.64745]
        });

        control.state.set('expanded', true);

        setRouted(true);
    }

    return (
        <>
            <div className="page page--contacts">
                <section className="sc__contacts">
                    <div className="sq__box mb-only">
                        <div className="sq__it sq__it--1"/>
                        <div className="sq__it sq__it--2"/>
                        <div className="sq__it sq__it--3"/>
                        <div className="sq__it sq__it--4"/>
                        <div className="sq__it sq__it--5"/>
                        <div className="sq__it sq__it--6"/>
                        <div className="sq__it sq__it--7"/>
                        <div className="sq__it sq__it--8"/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Контакты
                            </div>
                        </div>
                        <div className="contacts__list">
                            <div className="row">
                                <div className="contacts__it">
                                    <div className="contacts__it-header">
                                        Адрес офиса
                                    </div>
                                    <div className="contacts__it-descr">
                                        г. Москва, <br/>
                                        2-й Кожевнический переулок, д.12 <br/>
                                        Бизнес Парк «Горизонт» <br className="desk-only"/>
                                        подъезд 13, этаж 3
                                    </div>
                                </div>
                                <div className="contacts__it">
                                    <div className="contacts__it-header">
                                        Телефон
                                    </div>
                                    <div className="contacts__it-descr">
                                        +7 (495) 419-26-27
                                    </div>
                                </div>
                                <div className="contacts__it">
                                    <div className="contacts__it-header">
                                        E-mail
                                    </div>
                                    <div className="contacts__it-descr">
                                        dlitvintseva@v-key.ru
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sc__map">
                    <div id="map" className="map">

                    </div>
                    {!routed && (
                        <div onClick={()=>{
                            buildRoute();
                        }} className="btn__custom d-flex align-items-center justify-content-center">
                            ПОСТРОИТЬ МАРШРУТ В ЯНДЕКС.КАРТАХ
                        </div>
                    )}
                </section>
            </div>
        </>
    );
}

export default Home;
