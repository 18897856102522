import { useState, useEffect, useRef } from 'react'
import {useLocation} from "react-router-dom";
import {useModal} from "../context";
import {Link} from 'react-router-dom'
import Order from "./modals/Order";

function Footer() {
    const { show, hide} = useModal();

    return (
        <>
            <div id="footer" className="footer">
                <div className="container">
                    <div className="footer__logo">
                        <img src={require("../assets/img/footer-logo.svg").default}/>
                    </div>
                    <div className="footer__data-list">
                        <div className="row">
                            <div className="col-6 footer__data-col">
                                <div className="footer__data-it">
                                    <div className="footer__data-lbl">
                                        V-KEY
                                    </div>
                                    <div className="footer__data-val">
                                        Event агентство
                                    </div>
                                </div>
                                <div className="footer__data-it">
                                    <div className="footer__data-lbl">
                                        ОФИС
                                    </div>
                                    <div className="footer__data-val">
                                        г. Москва, <br/>
                                        2-й Кожевнический переулок, 12, подъезд 13, БП «Горизонт»
                                    </div>
                                </div>
                                <div className="footer__data-it">
                                    <div className="footer__data-val">
                                        <Link to="/contacts">Схема проезда</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 footer__data-col">
                                <div className="footer__data-it">
                                    <div className="footer__data-lbl">
                                        ТЕЛЕФОН
                                    </div>
                                    <div className="footer__data-val">
                                        + 7 (495) 419-26-27
                                    </div>
                                </div>
                                <div className="footer__data-it">
                                    <div className="footer__data-lbl">
                                        СОТРУДНИЧЕСТВО
                                    </div>
                                    <div className="footer__data-val">
                                        <a href="mailto:dlitvintseva@v-key.ru">dlitvintseva@v-key.ru</a>
                                    </div>
                                </div>
                                <div className="footer__data-it">
                                    <div className="footer__data-lbl">
                                        Входит
                                    </div>
                                    <div className="footer__data-val">
                                        <a href="https://thediversity.group/" target="_blank">в The Diversity Group</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__btn-box d-flex align-items-center justify-content-center">
                        <div onClick={()=>{
                            show(<Order/>, "modal--form modal--order");
                        }} className="footer__btn d-flex align-items-center justify-content-center">
                            <div className="footer__btn-title">
                                Заказать оценку стоимости проекта
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
