import { useState, useEffect, useRef } from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useModal, useProfile} from "../context";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Slider from "react-slick"
import { gsap } from 'gsap';
import Info from "../components/modals/Info";

let sl_set = {
    dots: true,
    arrows: false,
    speed: 600,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: true,
    pauseOnHover: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4500,
    appendDots: (dots) => <ul>{dots}</ul>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};

let sl_set_media = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 600,
    infinite: true,
    waitForAnimate: true,
    centerMode: true,
    centerPadding: 0,
    pauseOnHover: false,
    variableWidth: true,
    focusOnSelect: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                variableWidth: false
            }
        }
    ]
};

let sl_set_adv = {
    dots: false,
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 4500,
    infinite: true,
    waitForAnimate: true,
    centerMode: true,
    centerPadding: 0,
    pauseOnHover: false,
    variableWidth: true,
    focusOnSelect: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};

let sl_set_gall = {
    dots: false,
    arrows: false,
    speed: 600,
    infinite: true,
    waitForAnimate: true,
    centerPadding: 0,
    autoplay: true,
    autoplaySpeed: 4500,
    centerMode: true,
    pauseOnHover: false,
    focusOnSelect: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};

const shiftArray = (arr, count) => {
    let _newArr = [...arr];
    const len = _newArr.length
    _newArr.push(..._newArr.splice(0, (-count % len + len) % len))
    return _newArr
}

let servicesDef = [0, 1, 2, 3, 4, 5];


function Home() {
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState(servicesDef);

    const goToAnchor = (anchor) => {
        let el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
    }

    useEffect(() => {
        if(window.location.hash){
            setTimeout( () => {
                goToAnchor(window.location.hash.substring(1));
                window.history.pushState("", document.title, window.location.pathname
                    + window.location.search);
            }, 1000);
        }

        gsap
            .timeline({repeat: -1})
            .to('.sc__promo .sq__it', {
                rotate: '90%',
                duration: 0.3,
                delay: 0.15,
                stagger: {
                    each: 0.1,
                },
            })
            .to('.sc__promo .sq__it', {
                rotate: '180%',
                duration: 0.3,
                delay: 0.15,
                stagger: {
                    each: 0.1,
                },
            })
            .to('.sc__promo .sq__it', {
                rotate: '270%',
                duration: 0.3,
                delay: 0.15,
                stagger: {
                    each: 0.1,
                },
            })
            .to('.sc__promo .sq__it', {
                rotate: '360%',
                duration: 0.3,
                delay: 0.15,
                stagger: {
                    each: 0.1,
                },
            });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            let arr = shiftArray(servicesDef, 1);
            servicesDef = arr;
            setServices(arr);
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="page page--home">
                <section id="about" className="sc__promo">
                    <div className="container">
                        <div className="sq__box">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sc__promo-logo">
                            <img src={require("../assets/img/promo-logo.svg").default}/>
                        </div>
                        <div className="sc__promo-title seo-item">
                            <h1>
                                Event–агентство, создающее <br/>
                                мероприятия любого масштаба <br/>
                                и любой сложности <br/>
                                для B2B и B2C
                            </h1>
                        </div>
                        <div className="sc__promo-descr">
                            V-Key входит в группу компаний <a href="https://thediversity.group/" target="_blank">The Diversity Group</a>
                        </div>
                    </div>
                </section>
                <section id="facts" className="sc__facts">
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Самое важное <br/>
                                об агентстве
                            </div>
                        </div>
                        <div className="data__sl-box">
                            <div className="data__sl">
                                <Slider {...sl_set}>
                                    <div className="data__slide data__slide--1">
                                        <div className="data__slide-bl d-flex align-items-center">
                                            <div className="data__slide-header text-right">
                                                <div className="data__slide-title">
                                                    100 +
                                                </div>
                                            </div>
                                            <div className="data__slide-descr">
                                                реализаций мероприятий <br/>
                                                в B2B и B2C
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data__slide data__slide--2">
                                        <div className="data__slide-bl d-flex align-items-center">
                                            <div className="data__slide-header text-right">
                                                <div className="data__slide-title">
                                                    100 +
                                                </div>
                                            </div>
                                            <div className="data__slide-descr">
                                                разрешений в государственных <br/>
                                                структурах
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data__slide data__slide--3">
                                        <div className="data__slide-bl d-flex align-items-center">
                                            <div className="data__slide-header text-right">
                                                <div className="data__slide-title">
                                                    50 000 +
                                                </div>
                                            </div>
                                            <div className="data__slide-descr">
                                                гостей одновременно <br/>
                                                на мероприятиях
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data__slide data__slide--3">
                                        <div className="data__slide-bl d-flex align-items-center">
                                            <div className="data__slide-header d-flex align-items-center justify-content-end">
                                                <div className="data__slide-title">
                                                    1
                                                </div>
                                                <div className="data__slide-subtitle mb-small">
                                                    место
                                                </div>
                                            </div>
                                            <div className="data__slide-descr">
                                                «Креатив и стратегия 2020» <br/>
                                                по версии Adindex
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="cases" className="sc__cases">
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Кейсы <br/>
                                агентства
                            </div>
                        </div>
                        {(window.matchMedia('(min-width: 1024px)').matches && (
                            <>
                                <div className="sq__box sq__box--left desk-only">
                                    <div className="sq__it sq__it--1"/>
                                    <div className="sq__it sq__it--2"/>
                                    <div className="sq__it sq__it--3"/>
                                    <div className="sq__it sq__it--4"/>
                                    <div className="sq__it sq__it--5"/>
                                    <div className="sq__it sq__it--6"/>
                                    <div className="sq__it sq__it--7"/>
                                    <div className="sq__it sq__it--8"/>
                                </div>
                                <div className="sq__box sq__box--left-2 desk-only">
                                    <div className="sq__it sq__it--1"/>
                                    <div className="sq__it sq__it--2"/>
                                    <div className="sq__it sq__it--3"/>
                                    <div className="sq__it sq__it--4"/>
                                    <div className="sq__it sq__it--5"/>
                                    <div className="sq__it sq__it--6"/>
                                    <div className="sq__it sq__it--7"/>
                                    <div className="sq__it sq__it--8"/>
                                </div>
                                <div className="sq__box sq__box--right desk-only">
                                    <div className="sq__it sq__it--1"/>
                                    <div className="sq__it sq__it--2"/>
                                    <div className="sq__it sq__it--3"/>
                                    <div className="sq__it sq__it--4"/>
                                    <div className="sq__it sq__it--5"/>
                                    <div className="sq__it sq__it--6"/>
                                    <div className="sq__it sq__it--7"/>
                                    <div className="sq__it sq__it--8"/>
                                </div>
                                <div className="gall__box gall__box--main">
                                    <Link to="/cases/svoe" className="gall__img gall__img--1">
                                        <img src={require("../assets/img/home/g-1-img-1.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/svoe/logo.svg").default}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    ФЕРМЕРСКИЙ <br/>
                                                    ФЕСТИВАЛЬ «СВОЁ»
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/weber" className="gall__img gall__img--2">
                                        <img src={require("../assets/img/home/g-1-img-2.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/weber/logo-sm.png")}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    Организация гриль фестов
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/gorilla-habib" className="gall__img gall__img--3">
                                        <img src={require("../assets/img/home/g-1-img-3.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/gorilla/logo-2.png")}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    КОНФЕРЕНЦИЯ <br/>
                                                    С ХАБИБОМ ДЛЯ <br/>
                                                    GORILLA ENERGY
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/fitroo" className="gall__img gall__img--4">
                                        <img src={require("../assets/img/home/g-1-img-4.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/fitroo/logo.png")}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    ПРЕСС КОНФЕРЕНЦИЯ <br/>
                                                    ХАБИБ И FITROO
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/gorilla" className="gall__img gall__img--5">
                                        <img src={require("../assets/img/home/g-1-img-5.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/gorilla/logo.png")}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    Интеграция <br/>
                                                    Gorilla energy <br/>
                                                    в мероприятия
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/agrocode" className="gall__img gall__img--6">
                                        <img src={require("../assets/img/home/g-1-img-6.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/agrocode/logo-3.svg").default}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    ON LINE + OFFLINE <br/>
                                                    КОНФЕРЕНЦИЯ <br/>
                                                    "АГРОКОД"
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/gorilla-3" className="gall__img gall__img--7">
                                        <img src={require("../assets/img/home/g-1-img-7.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/gorilla-3/logo.png")}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    ИНТЕГРАЦИЯ <br/>
                                                    В VK FEST 2023 <br/>
                                                    C GORILLA <br/>
                                                    ENERGY
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/yarmarka" className="gall__img gall__img--8">
                                        <img src={require("../assets/img/home/g-1-img-8.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/agrocode/logo-4.svg").default}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    ПЕРВАЯ <br/>
                                                    ВСЕРОЙСИЙСКАЯ <br/>
                                                    ЯРМАРКА <br/>
                                                    ОРГАНИЧЕСКОЙ <br/>
                                                    ПРОДУКЦИИ
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/pridex" className="gall__img gall__img--9">
                                        <img src={require("../assets/img/home/g-1-img-9.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/pridex/logo.svg").default}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    PRIDEX 15 ЛЕТ: <br/>
                                                    ВЫСТАВКА <br/>
                                                    И КОНФЕРЕНЦИЯ ДЛЯ <br/>
                                                    ПАРТНЕРОВ БИЗНЕСА
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/samokat" className="gall__img gall__img--10">
                                        <img src={require("../assets/img/home/g-1-img-10.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/samokat/logo-2.svg").default}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    ОРГАНИЗОВАЛИ <br/>
                                                    ИНТЕГРАЦИЮ <br/>
                                                    В ЗАБЕГ.РФ <br/>
                                                    В 13 ГОРОДАХ <br/>
                                                    ОДНОВРЕМЕННО
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/hmao-ygra" className="gall__img gall__img--11">
                                        <img src={require("../assets/img/home/g-1-img-11.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/hmao-ygra/logo-front.png")}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    СТЕНД ХМАО-ЮГРА <br/>
                                                    НА ВЫСТАВКЕ <br/>
                                                    «РОССИЯ»
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/kaspersky" className="gall__img gall__img--12">
                                        <img src={require("../assets/img/home/g-1-img-12.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/kaspersky/logo.png")}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    НОВОГОДНИЙ <br/>
                                                    КОРНЕР ДЛЯ <br/>
                                                    KASPERSKY
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/en" className="gall__img gall__img--13">
                                        <img src={require("../assets/img/home/g-1-img-13.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/en/logo.svg").default}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    ДЕНЬ ЭНЕРГЕТИКА <br/>
                                                    ДЛЯ ЭН+
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/svoe-fest" className="gall__img gall__img--14">
                                        <img src={require("../assets/img/home/g-1-img-14.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/svoe-fest/logo-3.svg").default}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    РОЖДЕСТВЕНСКИЙ <br/>
                                                    ФЕСТИВАЛЬ <br/>
                                                    «СВОЕ» ДЛЯ <br/>
                                                    РОССЕЛЬХОЗБАНКА
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/cases/flagman" className="gall__img gall__img--15">
                                        <img src={require("../assets/img/home/g-1-img-15.jpg")}/>
                                        <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                            <div className="gall__hover-ct">
                                                <div className="gall__hover-logo">
                                                    <img src={require("../assets/img/cases/flagman/logo-2.png")}/>
                                                </div>
                                                <div className="gall__hover-title">
                                                    КАПСУЛА ВРЕМЕНИ <br/>
                                                    ДЛЯ ФСК Регион
                                                </div>
                                            </div>
                                        </div>
                                    </Link>

                                    <div className="cases__logos">
                                        <img src={require("../assets/img/logos.png")}/>
                                    </div>

                                    {/*<div className="cases__logo-1">
                                        <img src={require("../assets/img/cases-logo-1.png")}/>
                                    </div>
                                    <div className="cases__logo-2">
                                        <img src={require("../assets/img/cases/svoe/logo.svg").default}/>
                                    </div>
                                    <div className="cases__logo-5">
                                        <img src={require("../assets/img/cases/pridex/logo-2.svg").default}/>
                                    </div>
                                    <div className="cases__logo-6">
                                        <img src={require("../assets/img/cases/samokat/logo.svg").default}/>
                                    </div>
                                    <div className="cases__logo-3">
                                        <img src={require("../assets/img/cases/weber/logo-sm.png")}/>
                                    </div>
                                    <div className="cases__logo-4">
                                        <img src={require("../assets/img/cases-logo-2.png")}/>
                                    </div>*/}

                                </div>
                            </>
                        ))}

                        {(window.matchMedia('(max-width: 1023px)').matches && (
                            <>
                                <div className="gall__sl-box">
                                    <Slider {...sl_set_gall}>
                                        <div className="gall__sl-it gall__sl-it--1">
                                            <Link to="/cases/svoe" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-1.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/svoe/logo.svg").default}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                ФЕРМЕРСКИЙ <br/>
                                                                ФЕСТИВАЛЬ «СВОЁ»
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--2">
                                            <Link to="/cases/weber" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-2.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/weber/logo-sm.png")}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                Гриль фест <br/>
                                                                с мастер шефом <br/>
                                                                в Роза Хутор
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--3">
                                            <Link to="/cases/gorilla-habib" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-3.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/gorilla/logo-2.png")}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                КОНФЕРЕНЦИЯ <br/>
                                                                С ХАБИБОМ ДЛЯ <br/>
                                                                GORILLA ENERGY
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--4">
                                            <Link to="/cases/fitroo" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-4.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/fitroo/logo.png")}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                ПРЕСС КОНФЕРЕНЦИЯ <br/>
                                                                ХАБИБ И FITROO
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--5">
                                            <Link to="/cases/gorilla" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-5.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/gorilla/logo.png")}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                Интеграция <br/>
                                                                Gorilla energy <br/>
                                                                в мероприятия
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--6">
                                            <Link to="/cases/agrocode" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-6.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/svoe/logo.svg").default}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                ON LINE + OFFLINE <br/>
                                                                КОНФЕРЕНЦИЯ <br/>
                                                                "АГРОКОД"
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--7">
                                            <Link to="/cases/gorilla-3" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-7.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/gorilla-3/logo-2.png")}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                ИНТЕГРАЦИЯ <br/>
                                                                В VK FEST 2023 <br/>
                                                                C GORILLA ENERGY
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--8">
                                            <Link to="/cases/yarmarka" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-8.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/svoe/logo.svg").default}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                ПЕРВАЯ ВСЕРОЙСИЙСКАЯ ЯРМАРКА ОРГАНИЧЕСКОЙ ПРОДУКЦИИ
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--9">
                                            <Link to="/cases/pridex" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-9.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/pridex/logo.svg").default}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                PRIDEX 15 ЛЕТ: <br/>
                                                                ВЫСТАВКА И КОНФЕРЕНЦИЯ <br/>
                                                                ДЛЯ ПАРТНЕРОВ БИЗНЕСА
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--10">
                                            <Link to="/cases/samokat" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-10.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/samokat/logo-2.svg").default}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                ОРГАНИЗОВАЛИ ИНТЕГРАЦИЮ <br/>
                                                                В ЗАБЕГ.РФ <br/>
                                                                В 13 ГОРОДАХ ОДНОВРЕМЕННО
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--11">
                                            <Link to="/cases/hmao-ygra" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-11.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/hmao-ygra/logo-front.png")}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                СТЕНД ХМАО-ЮГРА <br/>
                                                                НА ВЫСТАВКЕ <br/>
                                                                «РОССИЯ»
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--12">
                                            <Link to="/cases/kaspersky" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-12.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/kaspersky/logo.png")}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                НОВОГОДНИЙ <br/>
                                                                КОРНЕР ДЛЯ <br/>
                                                                KASPERSKY
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--13">
                                            <Link to="/cases/en" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-13.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/en/logo.svg").default}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                ДЕНЬ ЭНЕРГЕТИКА <br/>
                                                                ДЛЯ ЭН+
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--14">
                                            <Link to="/cases/svoe-fest" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-14.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/svoe-fest/logo-3.svg").default}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                РОЖДЕСТВЕНСКИЙ <br/>
                                                                ФЕСТИВАЛЬ <br/>
                                                                «СВОЕ» ДЛЯ <br/>
                                                                РОССЕЛЬХОЗБАНКА
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="gall__sl-it gall__sl-it--15">
                                            <Link to="/cases/flagman" className="gall__sl-it-bl">
                                                <div className="gall__sl-it-img">
                                                    <img src={require("../assets/img/home/g-1-img-15.jpg")}/>
                                                    <div className="gall__hover-box d-flex align-items-center justify-content-center">
                                                        <div className="gall__hover-ct">
                                                            <div className="gall__hover-logo">
                                                                <img src={require("../assets/img/cases/flagman/logo-2.png")}/>
                                                            </div>
                                                            <div className="gall__hover-title">
                                                                КАПСУЛА ВРЕМЕНИ <br/>
                                                                ДЛЯ ФСК Регион
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </Slider>
                                    {/*<div className="cases__logo-1">
                                    <img src={require("../assets/img/cases-logo-1.png")}/>
                                </div>
                                <div className="cases__logo-2">
                                    <img src={require("../assets/img/cases/svoe/logo.svg").default}/>
                                </div>
                                <div className="cases__logo-5">
                                    <img src={require("../assets/img/cases/pridex/logo-2.svg").default}/>
                                </div>
                                <div className="cases__logo-6">
                                    <img src={require("../assets/img/cases/samokat/logo.svg").default}/>
                                </div>
                                <div className="cases__logo-3">
                                    <img src={require("../assets/img/cases/weber/logo-sm.png")}/>
                                </div>
                                <div className="cases__logo-4">
                                    <img src={require("../assets/img/cases-logo-2.png")}/>
                                </div>*/}
                                </div>
                                <div className="cases__logos-mb">
                                    <img src={require("../assets/img/logos-mb.png")}/>
                                </div>
                            </>
                        ))}
                    </div>
                </section>
                <section id="advants" className="sc__advants">
                    <div className="sq__box sq__box--left">
                        <div className="sq__it sq__it--1"/>
                        <div className="sq__it sq__it--2"/>
                        <div className="sq__it sq__it--3"/>
                        <div className="sq__it sq__it--4"/>
                        <div className="sq__it sq__it--5"/>
                        <div className="sq__it sq__it--6"/>
                        <div className="sq__it sq__it--7"/>
                        <div className="sq__it sq__it--8"/>
                    </div>
                    <div className="sq__box sq__box--right">
                        <div className="sq__it sq__it--1"/>
                        <div className="sq__it sq__it--2"/>
                        <div className="sq__it sq__it--3"/>
                        <div className="sq__it sq__it--4"/>
                        <div className="sq__it sq__it--5"/>
                        <div className="sq__it sq__it--6"/>
                        <div className="sq__it sq__it--7"/>
                        <div className="sq__it sq__it--8"/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                главные <br/>
                                Преимущества
                            </div>
                        </div>
                        <div className="adv__sl-box">
                            <div className="adv__sl">
                                <Slider {...sl_set_adv}>
                                    <div className="adv__slide">
                                        <div className="adv__slide-bl">
                                            <div className="adv__slide-ct">
                                                <div className="adv__slide-title">
                                                    Быстрое <br/>
                                                    согласование
                                                </div>
                                                <div className="adv__slide-descr">
                                                    Получение разрешений
                                                    с&nbsp;государственными
                                                    организациями <br/>
                                                    и&nbsp;любыми структурами
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adv__slide">
                                        <div className="adv__slide-bl">
                                            <div className="adv__slide-ct">
                                                <div className="adv__slide-title">
                                                    Надежное <br/>
                                                    партнерство
                                                </div>
                                                <div className="adv__slide-descr">
                                                    Договоренность, <br/>
                                                    мотивация <br/>
                                                    и&nbsp;организация <br/>
                                                    работы партнеров <br/>
                                                    мероприятия
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adv__slide">
                                        <div className="adv__slide-bl">
                                            <div className="adv__slide-ct">
                                                <div className="adv__slide-title">
                                                    Точные <br/>
                                                    тайминги
                                                </div>
                                                <div className="adv__slide-descr">
                                                    Контроль всех <br/>
                                                    этапов 24/7
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="resources" className="sc__resourses">
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                основные <br/>
                                ресурсы
                            </div>
                        </div>
                        <div className="media__sl-box media__sl-box--descr">
                            <div className="media__sl">
                                <Slider {...sl_set_media}>
                                    <div className="media__slide">
                                        <div className="media__slide-img">
                                            <img src={require("../assets/img/home/m-1.jpg")}/>
                                        </div>
                                        <div className="media__slide-title">
                                            Креатив и дизайн
                                        </div>
                                    </div>
                                    <div className="media__slide">
                                        <div className="media__slide-img">
                                            <img src={require("../assets/img/home/m-2.jpg")}/>
                                        </div>
                                        <div className="media__slide-title">
                                            Отдел логистики
                                        </div>
                                    </div>
                                    <div className="media__slide">
                                        <div className="media__slide-img">
                                            <img src={require("../assets/img/home/m-3.jpg")}/>
                                        </div>
                                        <div className="media__slide-title">
                                            Клиентский отдел
                                        </div>
                                    </div>
                                    <div className="media__slide">
                                        <div className="media__slide-img">
                                            <img src={require("../assets/img/home/m-4.jpg")}/>
                                        </div>
                                        <div className="media__slide-title">
                                            Отдел производства и закупки
                                        </div>
                                    </div>
                                    <div className="media__slide">
                                        <div className="media__slide-img">
                                            <img src={require("../assets/img/home/m-5.jpg")}/>
                                        </div>
                                        <div className="media__slide-title">
                                            Международный ресурс (СНГ и Прибалтика)
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="services" className="sc__services">
                    <div className="container">
                        <div className="sq__box sq__box--left desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sq__box sq__box--right desk-only">
                            <div className="sq__it sq__it--1"/>
                            <div className="sq__it sq__it--2"/>
                            <div className="sq__it sq__it--3"/>
                            <div className="sq__it sq__it--4"/>
                            <div className="sq__it sq__it--5"/>
                            <div className="sq__it sq__it--6"/>
                            <div className="sq__it sq__it--7"/>
                            <div className="sq__it sq__it--8"/>
                        </div>
                        <div className="sc__header">
                            <div className="sc__title">
                                ключевые <br/>
                                услуги
                            </div>
                        </div>
                        <div className="services__sq-box">
                            <div className="services__sq-bg">
                                <svg className="desk-only" width="1025" height="715" viewBox="0 0 1025 715" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M801.451 587H653.549C637.009 587 626.972 587 619.023 583.788C607.46 579.533 598.467 570.54 594.212 558.977C591 551.028 591 540.991 591 524.451V376.549C591 360.009 591 349.972 594.212 342.023C598.467 330.46 607.46 321.467 619.023 317.212C626.972 314 637.009 314 653.549 314H801.531C818.072 314 828.109 314 836.058 317.212C847.54 321.467 856.533 330.46 860.788 341.942C864 349.891 864 359.928 864 376.469V524.451C864 540.991 864 551.028 860.788 558.977C856.533 570.459 847.54 579.452 836.058 583.708C828.028 587 817.991 587 801.451 587ZM653.549 333.271C639.739 333.271 630.665 333.271 626.249 335.117L625.848 335.278C619.585 337.606 614.606 342.504 612.358 348.767L612.198 349.169C610.271 353.585 610.271 362.739 610.271 376.549V524.531C610.271 538.342 610.271 547.415 612.117 551.831L612.278 552.233C614.526 558.496 619.504 563.474 625.767 565.722L626.169 565.883C630.585 567.729 639.739 567.729 653.469 567.729H801.451C815.261 567.729 824.335 567.729 828.751 565.883L829.152 565.722C835.415 563.394 840.394 558.496 842.642 552.233L842.802 551.831C844.649 547.415 844.649 538.261 844.649 524.531V376.549C844.649 362.739 844.649 353.665 842.802 349.249L842.642 348.848C840.313 342.585 835.415 337.606 829.152 335.358L828.751 335.198C824.335 333.351 815.181 333.351 801.451 333.351H653.549V333.271Z" fill="white" fill-opacity="0.3"/>
                                    <path d="M586.451 715H438.549C422.009 715 411.972 715 404.023 711.788C392.46 707.533 383.467 698.54 379.212 686.977C376 679.028 376 668.991 376 652.451V504.549C376 488.009 376 477.972 379.212 470.023C383.467 458.46 392.46 449.467 404.023 445.212C411.972 442 422.009 442 438.549 442H586.531C603.072 442 613.109 442 621.058 445.212C632.54 449.467 641.533 458.46 645.788 469.942C649 477.891 649 487.928 649 504.469V652.451C649 668.991 649 679.028 645.788 686.977C641.533 698.459 632.54 707.452 621.058 711.708C613.028 715 602.991 715 586.451 715ZM438.549 461.271C424.739 461.271 415.665 461.271 411.249 463.117L410.848 463.278C404.585 465.606 399.606 470.504 397.358 476.767L397.198 477.169C395.271 481.585 395.271 490.739 395.271 504.549V652.531C395.271 666.342 395.271 675.415 397.117 679.831L397.278 680.233C399.526 686.496 404.504 691.474 410.767 693.722L411.169 693.883C415.585 695.729 424.739 695.729 438.469 695.729H586.451C600.261 695.729 609.335 695.729 613.751 693.883L614.152 693.722C620.415 691.394 625.394 686.496 627.642 680.233L627.802 679.831C629.649 675.415 629.649 666.261 629.649 652.531V504.549C629.649 490.739 629.649 481.665 627.802 477.249L627.642 476.848C625.313 470.585 620.415 465.606 614.152 463.358L613.751 463.198C609.335 461.351 600.181 461.351 586.451 461.351H438.549V461.271Z" fill="white" fill-opacity="0.3"/>
                                    <path d="M962.451 372H814.549C798.009 372 787.972 372 780.023 368.788C768.46 364.533 759.467 355.54 755.212 343.977C752 336.028 752 325.991 752 309.451V161.549C752 145.009 752 134.972 755.212 127.023C759.467 115.46 768.46 106.467 780.023 102.212C787.972 99 798.009 99 814.549 99H962.531C979.072 99 989.109 99 997.058 102.212C1008.54 106.467 1017.53 115.46 1021.79 126.942C1025 134.891 1025 144.928 1025 161.469V309.451C1025 325.991 1025 336.028 1021.79 343.977C1017.53 355.459 1008.54 364.452 997.058 368.708C989.028 372 978.991 372 962.451 372ZM814.549 118.271C800.739 118.271 791.665 118.271 787.249 120.117L786.848 120.278C780.585 122.606 775.606 127.504 773.358 133.767L773.198 134.169C771.271 138.585 771.271 147.739 771.271 161.549V309.531C771.271 323.342 771.271 332.415 773.117 336.831L773.278 337.233C775.526 343.496 780.504 348.474 786.767 350.722L787.169 350.883C791.585 352.729 800.739 352.729 814.469 352.729H962.451C976.261 352.729 985.335 352.729 989.751 350.883L990.152 350.722C996.415 348.394 1001.39 343.496 1003.64 337.233L1003.8 336.831C1005.65 332.415 1005.65 323.261 1005.65 309.531V161.549C1005.65 147.739 1005.65 138.665 1003.8 134.249L1003.64 133.848C1001.31 127.585 996.415 122.606 990.152 120.358L989.751 120.198C985.335 118.351 976.181 118.351 962.451 118.351H814.549V118.271Z" fill="white" fill-opacity="0.3"/>
                                    <path d="M210.451 371H62.5491C46.0085 371 35.9718 371 28.0226 367.788C16.4603 363.533 7.46735 354.54 3.21176 342.977C0 335.028 0 324.991 0 308.451V160.549C0 144.009 0 133.972 3.21176 126.023C7.46735 114.46 16.4603 105.467 28.0226 101.212C35.9718 98 46.0085 98 62.5491 98H210.531C227.072 98 237.109 98 245.058 101.212C256.54 105.467 265.533 114.46 269.788 125.942C273 133.891 273 143.928 273 160.469V308.451C273 324.991 273 335.028 269.788 342.977C265.533 354.459 256.54 363.452 245.058 367.708C237.028 371 226.991 371 210.451 371ZM62.5491 117.271C48.7385 117.271 39.6653 117.271 35.2491 119.117L34.8476 119.278C28.5847 121.606 23.6065 126.504 21.3582 132.767L21.1976 133.169C19.2706 137.585 19.2706 146.739 19.2706 160.549V308.531C19.2706 322.342 19.2706 331.415 21.1174 335.831L21.2779 336.233C23.5262 342.496 28.5044 347.474 34.7674 349.722L35.1688 349.883C39.585 351.729 48.7385 351.729 62.4688 351.729H210.451C224.261 351.729 233.335 351.729 237.751 349.883L238.152 349.722C244.415 347.394 249.394 342.496 251.642 336.233L251.802 335.831C253.649 331.415 253.649 322.261 253.649 308.531V160.549C253.649 146.739 253.649 137.665 251.802 133.249L251.642 132.848C249.313 126.585 244.415 121.606 238.152 119.358L237.751 119.198C233.335 117.351 224.181 117.351 210.451 117.351H62.5491V117.271Z" fill="white" fill-opacity="0.3"/>
                                    <path d="M627.666 403H409.334C384.917 403 370.101 403 358.367 398.259C341.299 391.977 328.023 378.701 321.741 361.633C317 349.899 317 335.083 317 310.666V92.3344C317 67.9174 317 53.1012 321.741 41.3668C328.023 24.2985 341.299 11.0232 358.367 4.74118C370.101 0 384.917 0 409.334 0H627.784C652.201 0 667.017 0 678.752 4.74118C695.702 11.0232 708.977 24.2985 715.259 41.2482C720 52.9826 720 67.7988 720 92.2159V310.666C720 335.083 720 349.899 715.259 361.633C708.977 378.583 695.702 391.858 678.752 398.14C666.899 403 652.083 403 627.666 403ZM409.334 28.4471C388.947 28.4471 375.554 28.4471 369.034 31.1732L368.442 31.4103C359.196 34.8476 351.848 42.0779 348.529 51.3232L348.292 51.9159C345.447 58.435 345.447 71.9474 345.447 92.3344V310.784C345.447 331.171 345.447 344.565 348.173 351.084L348.41 351.677C351.729 360.922 359.078 368.271 368.323 371.59L368.916 371.827C375.435 374.553 388.947 374.553 409.216 374.553H627.666C648.053 374.553 661.447 374.553 667.966 371.827L668.558 371.59C677.804 368.152 685.152 360.922 688.471 351.677L688.708 351.084C691.434 344.565 691.434 331.053 691.434 310.784V92.3344C691.434 71.9474 691.434 58.5535 688.708 52.0344L688.471 51.4418C685.034 42.1965 677.804 34.8476 668.558 31.5288L667.966 31.2918C661.447 28.5656 647.934 28.5656 627.666 28.5656H409.334V28.4471Z" fill="#F0D264"/>
                                    <path d="M372.451 585H224.549C208.009 585 197.972 585 190.023 581.788C178.46 577.533 169.467 568.54 165.212 556.977C162 549.028 162 538.991 162 522.451V374.549C162 358.009 162 347.972 165.212 340.023C169.467 328.46 178.46 319.467 190.023 315.212C197.972 312 208.009 312 224.549 312H372.531C389.072 312 399.109 312 407.058 315.212C418.54 319.467 427.533 328.46 431.788 339.942C435 347.891 435 357.928 435 374.469V522.451C435 538.991 435 549.028 431.788 556.977C427.533 568.459 418.54 577.452 407.058 581.708C399.028 585 388.991 585 372.451 585ZM224.549 331.271C210.739 331.271 201.665 331.271 197.249 333.117L196.848 333.278C190.585 335.606 185.606 340.504 183.358 346.767L183.198 347.169C181.271 351.585 181.271 360.739 181.271 374.549V522.531C181.271 536.342 181.271 545.415 183.117 549.831L183.278 550.233C185.526 556.496 190.504 561.474 196.767 563.722L197.169 563.883C201.585 565.729 210.739 565.729 224.469 565.729H372.451C386.261 565.729 395.335 565.729 399.751 563.883L400.152 563.722C406.415 561.394 411.394 556.496 413.642 550.233L413.802 549.831C415.649 545.415 415.649 536.261 415.649 522.531V374.549C415.649 360.739 415.649 351.665 413.802 347.249L413.642 346.848C411.313 340.585 406.415 335.606 400.152 333.358L399.751 333.198C395.335 331.351 386.181 331.351 372.451 331.351H224.549V331.271Z" fill="white" fill-opacity="0.3"/>
                                </svg>
                                <svg className="mb-only" width="572" height="1025" viewBox="0 0 572 1025" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M397.666 608H179.334C154.917 608 140.101 608 128.367 603.259C111.299 596.977 98.0232 583.701 91.7412 566.633C87 554.899 87 540.083 87 515.666V297.334C87 272.917 87 258.101 91.7412 246.367C98.0232 229.299 111.299 216.023 128.367 209.741C140.101 205 154.917 205 179.334 205H397.784C422.201 205 437.017 205 448.752 209.741C465.702 216.023 478.977 229.299 485.259 246.248C490 257.983 490 272.799 490 297.216V515.666C490 540.083 490 554.899 485.259 566.633C478.977 583.583 465.702 596.858 448.752 603.14C436.899 608 422.083 608 397.666 608ZM179.334 233.447C158.947 233.447 145.554 233.447 139.034 236.173L138.442 236.41C129.196 239.848 121.848 247.078 118.529 256.323L118.292 256.916C115.447 263.435 115.447 276.947 115.447 297.334V515.784C115.447 536.171 115.447 549.565 118.173 556.084L118.41 556.677C121.729 565.922 129.078 573.271 138.323 576.59L138.916 576.827C145.435 579.553 158.947 579.553 179.216 579.553H397.666C418.053 579.553 431.447 579.553 437.966 576.827L438.558 576.59C447.804 573.152 455.152 565.922 458.471 556.677L458.708 556.084C461.434 549.565 461.434 536.053 461.434 515.784V297.334C461.434 276.947 461.434 263.554 458.708 257.034L458.471 256.442C455.034 247.196 447.804 239.848 438.558 236.529L437.966 236.292C431.447 233.566 417.934 233.566 397.666 233.566H179.334V233.447Z" fill="#F0D264"/>
                                    <path d="M216.451 812H68.5491C52.0085 812 41.9718 812 34.0226 808.788C22.4603 804.533 13.4674 795.54 9.21176 783.977C6 776.028 6 765.991 6 749.451V601.549C6 585.009 6 574.972 9.21176 567.023C13.4674 555.46 22.4603 546.467 34.0226 542.212C41.9718 539 52.0085 539 68.5491 539H216.531C233.072 539 243.109 539 251.058 542.212C262.54 546.467 271.533 555.46 275.788 566.942C279 574.891 279 584.928 279 601.469V749.451C279 765.991 279 776.028 275.788 783.977C271.533 795.459 262.54 804.452 251.058 808.708C243.028 812 232.991 812 216.451 812ZM68.5491 558.271C54.7385 558.271 45.6653 558.271 41.2491 560.117L40.8476 560.278C34.5847 562.606 29.6065 567.504 27.3582 573.767L27.1976 574.169C25.2706 578.585 25.2706 587.739 25.2706 601.549V749.531C25.2706 763.342 25.2706 772.415 27.1174 776.831L27.2779 777.233C29.5262 783.496 34.5044 788.474 40.7674 790.722L41.1688 790.883C45.585 792.729 54.7385 792.729 68.4688 792.729H216.451C230.261 792.729 239.335 792.729 243.751 790.883L244.152 790.722C250.415 788.394 255.394 783.496 257.642 777.233L257.802 776.831C259.649 772.415 259.649 763.261 259.649 749.531V601.549C259.649 587.739 259.649 578.665 257.802 574.249L257.642 573.848C255.313 567.585 250.415 562.606 244.152 560.358L243.751 560.198C239.335 558.351 230.181 558.351 216.451 558.351H68.5491V558.271Z" fill="white" fill-opacity="0.3"/>
                                    <path d="M509.451 812H361.549C345.009 812 334.972 812 327.023 808.788C315.46 804.533 306.467 795.54 302.212 783.977C299 776.028 299 765.991 299 749.451V601.549C299 585.009 299 574.972 302.212 567.023C306.467 555.46 315.46 546.467 327.023 542.212C334.972 539 345.009 539 361.549 539H509.531C526.072 539 536.109 539 544.058 542.212C555.54 546.467 564.533 555.46 568.788 566.942C572 574.891 572 584.928 572 601.469V749.451C572 765.991 572 776.028 568.788 783.977C564.533 795.459 555.54 804.452 544.058 808.708C536.028 812 525.991 812 509.451 812ZM361.549 558.271C347.739 558.271 338.665 558.271 334.249 560.117L333.848 560.278C327.585 562.606 322.606 567.504 320.358 573.767L320.198 574.169C318.271 578.585 318.271 587.739 318.271 601.549V749.531C318.271 763.342 318.271 772.415 320.117 776.831L320.278 777.233C322.526 783.496 327.504 788.474 333.767 790.722L334.169 790.883C338.585 792.729 347.739 792.729 361.469 792.729H509.451C523.261 792.729 532.335 792.729 536.751 790.883L537.152 790.722C543.415 788.394 548.394 783.496 550.642 777.233L550.802 776.831C552.649 772.415 552.649 763.261 552.649 749.531V601.549C552.649 587.739 552.649 578.665 550.802 574.249L550.642 573.848C548.313 567.585 543.415 562.606 537.152 560.358L536.751 560.198C532.335 558.351 523.181 558.351 509.451 558.351H361.549V558.271Z" fill="white" fill-opacity="0.3"/>
                                    <path d="M210.451 273H62.5491C46.0085 273 35.9718 273 28.0226 269.788C16.4603 265.533 7.46735 256.54 3.21176 244.977C0 237.028 0 226.991 0 210.451V62.5491C0 46.0085 0 35.9718 3.21176 28.0226C7.46735 16.4603 16.4603 7.46735 28.0226 3.21176C35.9718 0 46.0085 0 62.5491 0H210.531C227.072 0 237.109 0 245.058 3.21176C256.54 7.46735 265.533 16.4603 269.788 27.9424C273 35.8915 273 45.9282 273 62.4688V210.451C273 226.991 273 237.028 269.788 244.977C265.533 256.459 256.54 265.452 245.058 269.708C237.028 273 226.991 273 210.451 273ZM62.5491 19.2706C48.7385 19.2706 39.6653 19.2706 35.2491 21.1174L34.8476 21.2779C28.5847 23.6065 23.6065 28.5044 21.3582 34.7674L21.1976 35.1688C19.2706 39.585 19.2706 48.7385 19.2706 62.5491V210.531C19.2706 224.342 19.2706 233.415 21.1174 237.831L21.2779 238.233C23.5262 244.496 28.5044 249.474 34.7674 251.722L35.1688 251.883C39.585 253.729 48.7385 253.729 62.4688 253.729H210.451C224.261 253.729 233.335 253.729 237.751 251.883L238.152 251.722C244.415 249.394 249.394 244.496 251.642 238.233L251.802 237.831C253.649 233.415 253.649 224.261 253.649 210.531V62.5491C253.649 48.7385 253.649 39.6653 251.802 35.2491L251.642 34.8476C249.313 28.5847 244.415 23.6065 238.152 21.3582L237.751 21.1976C233.335 19.3509 224.181 19.3509 210.451 19.3509H62.5491V19.2706Z" fill="white" fill-opacity="0.3"/>
                                    <path d="M493.451 273H345.549C329.009 273 318.972 273 311.023 269.788C299.46 265.533 290.467 256.54 286.212 244.977C283 237.028 283 226.991 283 210.451V62.5491C283 46.0085 283 35.9718 286.212 28.0226C290.467 16.4603 299.46 7.46735 311.023 3.21176C318.972 0 329.009 0 345.549 0H493.531C510.072 0 520.109 0 528.058 3.21176C539.54 7.46735 548.533 16.4603 552.788 27.9424C556 35.8915 556 45.9282 556 62.4688V210.451C556 226.991 556 237.028 552.788 244.977C548.533 256.459 539.54 265.452 528.058 269.708C520.028 273 509.991 273 493.451 273ZM345.549 19.2706C331.739 19.2706 322.665 19.2706 318.249 21.1174L317.848 21.2779C311.585 23.6065 306.606 28.5044 304.358 34.7674L304.198 35.1688C302.271 39.585 302.271 48.7385 302.271 62.5491V210.531C302.271 224.342 302.271 233.415 304.117 237.831L304.278 238.233C306.526 244.496 311.504 249.474 317.767 251.722L318.169 251.883C322.585 253.729 331.739 253.729 345.469 253.729H493.451C507.261 253.729 516.335 253.729 520.751 251.883L521.152 251.722C527.415 249.394 532.394 244.496 534.642 238.233L534.802 237.831C536.649 233.415 536.649 224.261 536.649 210.531V62.5491C536.649 48.7385 536.649 39.6653 534.802 35.2491L534.642 34.8476C532.313 28.5847 527.415 23.6065 521.152 21.3582L520.751 21.1976C516.335 19.3509 507.181 19.3509 493.451 19.3509H345.549V19.2706Z" fill="white" fill-opacity="0.3"/>
                                    <path d="M360.451 1025H212.549C196.009 1025 185.972 1025 178.023 1021.79C166.46 1017.53 157.467 1008.54 153.212 996.977C150 989.028 150 978.991 150 962.451V814.549C150 798.009 150 787.972 153.212 780.023C157.467 768.46 166.46 759.467 178.023 755.212C185.972 752 196.009 752 212.549 752H360.531C377.072 752 387.109 752 395.058 755.212C406.54 759.467 415.533 768.46 419.788 779.942C423 787.891 423 797.928 423 814.469V962.451C423 978.991 423 989.028 419.788 996.977C415.533 1008.46 406.54 1017.45 395.058 1021.71C387.028 1025 376.991 1025 360.451 1025ZM212.549 771.271C198.739 771.271 189.665 771.271 185.249 773.117L184.848 773.278C178.585 775.606 173.606 780.504 171.358 786.767L171.198 787.169C169.271 791.585 169.271 800.739 169.271 814.549V962.531C169.271 976.342 169.271 985.415 171.117 989.831L171.278 990.233C173.526 996.496 178.504 1001.47 184.767 1003.72L185.169 1003.88C189.585 1005.73 198.739 1005.73 212.469 1005.73H360.451C374.261 1005.73 383.335 1005.73 387.751 1003.88L388.152 1003.72C394.415 1001.39 399.394 996.496 401.642 990.233L401.802 989.831C403.649 985.415 403.649 976.261 403.649 962.531V814.549C403.649 800.739 403.649 791.665 401.802 787.249L401.642 786.848C399.313 780.585 394.415 775.606 388.152 773.358L387.751 773.198C383.335 771.351 374.181 771.351 360.451 771.351H212.549V771.271Z" fill="white" fill-opacity="0.3"/>
                                </svg>

                            </div>
                            <div className={'services__it services__it--' + (services[0])}>
                                <div className="services__it-ct d-flex align-items-center justify-content-center">
                                    <div className="services__it-title">
                                        Мероприятия <br/>
                                        для сотрудников
                                    </div>
                                </div>
                            </div>
                            <div className={'services__it services__it--' + (services[1])}>
                                <div className="services__it-ct d-flex align-items-center justify-content-center">
                                    <div className="services__it-title">
                                        Фестивали: <br/>
                                        уличные <br/>
                                        и городские
                                    </div>
                                </div>
                            </div>
                            <div className={'services__it services__it--' + (services[2])}>
                                <div className="services__it-ct d-flex align-items-center justify-content-center">
                                    <div className="services__it-title">
                                        Интеграция <br/>
                                        в фестивали <br/>
                                        и выставки
                                    </div>
                                </div>
                            </div>
                            <div className={'services__it services__it--' + (services[3])}>
                                <div className="services__it-ct d-flex align-items-center justify-content-center">
                                    <div className="services__it-title">
                                        Обучающие <br/>
                                        мероприятия
                                    </div>
                                </div>
                            </div>
                            <div className={'services__it services__it--' + (services[4])}>
                                <div className="services__it-ct d-flex align-items-center justify-content-center">
                                    <div className="services__it-title">
                                        Мероприятия <br/>
                                        для <br/>
                                        потребителей
                                    </div>
                                </div>
                            </div>
                            <div className={'services__it services__it--' + (services[5])}>
                                <div className="services__it-ct d-flex align-items-center justify-content-center">
                                    <div className="services__it-title">
                                        Выездные <br/>
                                        мероприятия, <br/>
                                        MICE
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Home;
